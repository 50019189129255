<template>

<div id="deactivateaccount" class = "pagefixedwidth2">
    <TopBar  simple hidedropdown>
      <template v-slot:title>
        Delete account
      </template>
      <template v-slot:icon>
        <!-- <i class="fal fa-border-all align-self-center "></i> -->
      </template>
    </TopBar>
   <main class="main-wrapper">

    <div>
      

<h4  class="font-weight-bold clearfix">Is this goodbye? We are sorry to see you go.</h4>





   <strong>Here is what will happen:</strong>
      <ul class="pl-3">        
       
        <li>Your personal profile <span v-if="userdetails">({{ userdetails }})</span> will be deleted and you will no longer be able to sign in using your account.</li>
        <li>Any user-added content (status updates, reports, comments and replies) will be retained but the author will be changed to ‘Former User’, an anonymous user. </li>
        <li>Any boards and/or objectives where you are the only owner will become inactive and unavailable to all other users.</li>
        <li>Any draft reports that are currently locked by you will be unlocked.</li>
      </ul>

      <p v-if="working"><strong  class="text-warning">Analyzing items to be deleted... please wait. <i class="fa fa-circle-o-notch fa-spin"></i></strong></p>

      <strong v-if="( selforganizations && selforganizations.length > 0 ) || ( selfboards && selfboards.length > 0 ) || selfobjectives && selfobjectives.length > 0">Before proceeding:</strong>

      <ul class="pl-3">

        <li v-if="selforganizations && selforganizations.length > 0">You are the only administrator of the following organizations. The organizations will be permanently deleted unless you add an additional administrator. Click on the title for member administration:  

          <ul v-if="selforganizations" class="pl-3">
            
            <template v-for="organization in selforganizations"> 

              <li :key="organization._id" v-if="organization.numowner==1"> <a class="actionprimary" :href="'/' + organization.key + '/organizations/members'" target="_blank">{{organization.name}}</a></li>
            </template>

          </ul>
        </li>

        <li v-if="selfboards && selfboards.length > 0">You are the only owner of the following boards. The boards will be permanently deleted unless you add an additional owner. Click on the title for member administration: 

          <ul v-if="selfboards" class="pl-3">
           <template v-for="board in selfboards">
            <li :key="board._id" v-if="board.numowner==1"> <a :href="'/' + board.orgkey + '/boards/'+board._id+'/objectives?page=members'" class="actionprimary" target="_blank" >{{board.name}}</a> </li>
            </template>
          </ul>

        </li>
        

        <li v-if="selfobjectives && selfobjectives.length > 0">You are the only owner of the following objectives. The objectives will be permanently deleted unless you add an additional owner. Click on the title for member administration: 

          <ul v-if="selfobjectives" class="pl-3">
            
            <template v-for="objective in selfobjectives">
              <li :key="objective._id" v-if="objective.numowner==1"> <a class="actionprimary" :href="'/'+objective.orgkey+'/objectives?page=omembers&oid='+objective._id" target="_blank">{{objective.name}}</a> </li>
            </template>

          </ul>
        </li>

        

         <li v-if="newsletters && newsletters.length > 0">If you need any specific user-added content removed, then please contact us <a href="https://flowe.io/support" class="actionprimary">contact us <i class="far fa-external-link" aria-hidden="true"></i>
</a> .</li>

      </ul>
        
      <b-alert  v-if="working == false" class ="mb-3 font-weight-bold text-center" show variant="danger"> You will be signed out immediately. <br> Account deletion is permanent and cannot be undone.</b-alert>
     

      <div class="" v-if="working == false">

      <div v-if="mode=='password'">
        
      <label>Enter your password to continue</label>
      <div class=" pl-0 pb-2"><input type="Password" class="hide"> 
        <input type="password" class="form-control" placeholder="Password" v-model="verifypassword"></div>
        <p class="text-danger" v-if="error=='passwordempty'">Please enter your password.</p>
        <p class="text-danger" v-if="error=='passwordinvalid'">The password you have entered is incorrect.

</p>
      </div>


      <div v-if="mode=='catchword'">

      <label>Type "DELETE" to continue</label>
      <div class="  pl-0 pb-2">
        <input type="text" class="form-control" placeholder="Password" v-model="verifycatchword"></div>
        <p class="text-danger" v-if="error=='catchwordempty'">You must type the word ‘DELETE’ (all caps) in order to proceed.</p>
        <p class="text-danger" v-if="error=='catchwordinvalid'">You must type the word ‘DELETE’ (all caps) in order to proceed.</p>

      </div>

      <div class="mb-3">


      <button-gruppe  @no="gotoprofile" @yes="verifyuser()" :text1="'Delete my account'" :variant1="'danger'"/>
      </div>
      </div>

     
        

    </div>

</main>
</div>
</template>	
<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
  @import'@/scss/abstracts/mixin.scss';



</style>

<script type="text/javascript"></script>
<script>

  import Userapi from '@/services/api/users'

  import {TopBar} from '@/components/topbar'

	export default{
		data(){
			return{
          userid : null,
          users : [],
          pick :"",
          boards:[],
          objectives:[],
          newsletters:[],
          published:[],
          userdetails:"",
          pre : {},
          confirmdeactivate : false,
          finalreview : false,
          selfboards : [],
          selfobjectives : [],
          selforganizations : [],
          error:"",
          verifypassword:"",
          verifycatchword:"",
          confirmtoken:"",
          working:false

        }
        },
        filters: {},
        computed : {

          orgkey(){
            return this.$route.params.orgname
          },

          mode(){
            
            var toreturn = ""
            if( (this.userinfo.signinlocal == '' && this.userinfo.signingoogle != '') || ( this.userinfo.signinmethods && this.userinfo.signinmethods.length > 0 )  ){
              toreturn = 'catchword'
            }else if( this.userinfo.signinlocal == 'email' ) {
              toreturn = 'password'
            }

            return toreturn
          },

         userinfo(){
            return this.$store.getters.user
          },

          token(){
            return localStorage.token
          }

        },
        mounted() {

          document.title ='Delete account | Flowe'


          $('#preloader').addClass('hide')

          this.updateprofile()

        },
        methods: {
          gotoprofile(){
            this.$router.push('/')
          },
          verifyuser(){

            this.error = ""
            this.working = true

            let self = this

            var action = "deactivateaccount"

            Userapi.Verifyuser( { "t":this.token, "password":this.verifypassword, "catchword":this.verifycatchword, "mode":this.mode, "action":action } )
            .then( function(response){

            if( response.data.ok ){

              self.confirmtoken = response.data.confirmtoken
              self.$router.push({ name:response.data.route, query:{confirmtoken:self.confirmtoken} })

            }else{

              self.error = response.data.message

            }


            })
            .catch(error => console.log(error))
            .finally( () => {
               self.working = false
            })

          },


          initiatedelete(){

            this.$router.push({ name:"verify", query:{action:"deactivateaccount"} })
          },

          finishdeactivate(){

            this.confirmdeactivate = false
            this.finalreview = true
          },

          doconfirmdeactivate(){

            this.updateprofile()
            this.confirmdeactivate = true
            //this.finalreview = true

          },


          updateprofile( tag, newvalue, callback ){

            let self = this

            this.working = true


            Userapi.Predeactivate( this.token )
            .then( function(response){

            if(response.data.ok == false ){

              //console.log(response)

              var details = []

              self.pre = response.data

              self.boards = response.data.boards
              self.objectives = response.data.objectives
              self.newsletters = response.data.newsletters
              self.published = response.data.published
              self.selfboards = response.data.selfboards
              self.selfobjectives = response.data.selfobjectives
              self.selforganizations = response.data.selforganizations

              self.userdetails = response.data.userdetails.join(", ")

            }else{

            }


            })
            .catch(error => console.log(error))
            .finally( () => {

              self.working = false
            })

          },



        },
        components:{
    TopBar
  }
      }
</script>